import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import { Card, Col, Pagination, Row, Space, Typography } from "antd"
import { graphql, Link, navigate } from "gatsby"
import { JsonDebugger } from "../components/json-debugger"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../utils/html-serializer"
import { GatsbyImage } from "gatsby-plugin-image"
import PageContent from "../components/page-content"
import { cardPreview } from "./testimonials.module.less"
const { Title } = Typography

const TestimonialsPage: React.FC<{
  data: any
}> = ({
  data,
  pageContext: {
    testimonials: edges,
    pageInfo: {
      currentPage = 1,
      perPage = 1,
      totalCount = 1,
      hasNextPage = false,
      hasPreviousPage = false,
    } = {},
  },
}) => {
  // console.log({ rest })

  // if (!data) return null
  // const { edges, pageInfo } = data.allPrismicTestimonial
  const testimonials = edges.map(({ node }) => node)
  const pageInfo = {
    currentPage,
    totalCount,
    perPage,
    hasNextPage,
    hasPreviousPage,
  }

  const testimonialCards = testimonials.map((testimonial) => {
    const firstImage =
      Array.isArray(testimonial.data.images) &&
      testimonial.data.images[0].image.url !== null
        ? testimonial.data.images[0].image
        : null
    const image = firstImage ? (
      <GatsbyImage
        alt={""}
        // objectFit={"contain"}
        objectFit={"fullWidth"}
        image={firstImage.gatsbyImageData}
      />
    ) : null
    return (
      <Link key={testimonial.id} to={testimonial.url}>
        <Card cover={image} hoverable>
          <Card.Meta
            title={testimonial.data.title}
            description={
              <span className={cardPreview}>
                <RichText
                  render={testimonial.data.message.richText}
                  // serializeHyperlink={CustomLink}
                  htmlSerializer={htmlSerializer}
                />
              </span>
            }
          />
          {/* {testimonial.data.sort_rating ||0} */}
        </Card>
      </Link>
    )
  })

  return (
    <Layout>
      <Seo title={"Dakine 420 Testimonials"} />
      <PageContent>
        <Title>Testimonials</Title>
        <main>
          <Space direction={"vertical"}>
            <Row gutter={[24, 24]}>
              {testimonialCards && testimonialCards.length > 0 ? (
                testimonialCards.map((testimonialCard, index) => (
                  <Col key={index} xs={24} sm={12} md={8} lg={6}>
                    {testimonialCard}
                  </Col>
                ))
              ) : (
                <p>No testimonials yet</p>
              )}
            </Row>
            <Pagination
              current={pageInfo.currentPage}
              showTotal={(total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total}`
              }
              defaultPageSize={pageInfo.perPage}
              total={pageInfo.totalCount}
              onChange={(page) =>
                navigate(`/testimonials/${page > 1 ? page : ""}`)
              }
            />
          </Space>
        </main>
        {/* <JsonDebugger json={{ testimonials, data }} /> */}
      </PageContent>
    </Layout>
  )
}

export default TestimonialsPage

// Popup modal for details and images NOT a unique visit page
